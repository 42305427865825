@import '../styles/reset.css';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
blockquote,
q {
  quotes: none;
}
ol,
ul {
  list-style: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* QA-1656: 針對字型進行優化，英文採用 Lato 字型，中文用 Nato Sans TC(思源)字型，也確保在 IE 環境下的閱讀字型體驗是一致的 */
/* VideoJS 是用於投資駕訓班中的播放列的按鈕圖示字型，最主要是在 node_modules/video.js/dist/video-js.css 中被定義 */
html {
  font-family: Lato, 'Noto Sans TC', 'Pingfang TC', 'Microsoft Jhenghei', Arial, VideoJS, sans-serif;
}

body {
  background-color: #f6f8fc;
  -ms-overflow-style: none; /* IE and Edge */
}

a {
  text-decoration: none;
  color: #383838;
}

:root {
  --toastify-color-success: #6cdde8 !important;
  --toastify-color-info: #ffb431 !important;
}
